/* eslint-disable import/no-anonymous-default-export */
export default {
  REACT_APP_MSAL_AUTHORITY_URL: 'https://login.microsoftonline.com/',
  REACT_APP_MSAL_CLIENT_ID: '9f2ec8b1-020c-4e0e-9b77-96df81c8eb35',
  REACT_APP_MSAL_TENANT_ID: '72f988bf-86f1-41af-91ab-2d7cd011db47',
  REACT_APP_MSAL_REDIRECT_URL: 'https://parking.microsoft.com',
  REACT_APP_MSAL_SMART_PARKING_SCOPE_URL: 'https://microsoft.onmicrosoft.com/smartParkingAPI/.default',
  REACT_APP_MSAL_GRAPH_ENDPOINT_URL: 'https://graph.microsoft.com/v1.0/me',
  REACT_APP_MSAL_ADMIN_ROLES: ['Parking.Admin'],
  REACT_APP_MSAL_ADMIN_REPORTS_ROLES: ['ParkingReports.Admin'],
  REACT_APP_API_SMARTPARKING_APIM_KEY: '347ff7db663c4de5bd134bb994f19b38;product=smart-parking',
  REACT_APP_API_SMARTPARKING_APIM_BASEURL: 'https://dwp.azure-api.net',
  REACT_APP_API_SMARTPARKING_ENVIRONMENT: 'prod',
  GENERATE_SOURCEMAP: false,
};
